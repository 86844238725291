<template>
  <div>
    <!--  -->
    <NavigationSubNav main-route="data" :title="actionName" />
    <!--  -->
    <div class="container-fluid pb-1">
      <Loader
        v-if="showLoader"
        :progress="progress"
        :description="loadingPrompt"
        :title="loadingTitle"
      />

      <div v-else class="mb-sm-4 mx-3 my-4 row" style="top: 75px">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
                <p>{{ message }}</p>
              </div>
              <div>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="shadow float-right"
                  variant="primary"
                  :disabled="editting.length == 0"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 float-right btn-warning shadow"
                >
                  Advance Filters
                </button>
              </div>
            </div>

            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-2 mb-1">
                <span v-if="edittingEnabled">{{ tableDescription }}</span>
              </div>

              <div v-for="entry in filters" :key="entry.name" class="float-right col">
                <el-select
                  v-model="tableFilters[entry.name]"
                  clearable
                  filterable
                  :placeholder="entry.label"
                  class="mr-2 row"
                >
                  <el-option
                    v-for="option in entry.options"
                    :key="option"
                    :value="option"
                    :label="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2 mb-1 float-right">
                <el-button
                  :disabled="editting.length == 0"
                  type="primary"
                  icon="el-icon-edit"
                  class="action-btns"
                  @click.prevent="revertToEdit"
                ></el-button>
                <el-tooltip
                  :disabled="selectedRows.length <= 0"
                  effect="dark"
                  content="Delete selected Rows"
                  placement="top"
                >
                  <el-button
                    :disabled="editting.length == 0"
                    type="danger"
                    class="action-btns"
                    icon="el-icon-delete"
                    @click.prevent="handleDeleteSelectedCells"
                  >
                    <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                      selectedRows.length
                    }}</small>
                  </el-button>
                </el-tooltip>
              </div>
            </div>

            <div v-if="duplicateRows.length > 0" class="row pt-2 px-3 py-2">
              <div class="col-lg-2 mb-1">
                <span style="color: #f56c6c">{{ duplicateRows.length }} duplicates found</span>
              </div>
            </div>

            <TableComponent
              :fields="fields"
              bordered
              :data="preview"
              :empty-text="emptyText"
              :loading="tableLoading"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
            />

            <div class="row mb-3 p-4 pt-3 px-3 py-3">
              <div class="col-lg-12">
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-danger shadow"
                  @click.prevent="cancel"
                >
                  Cancel
                </button>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-primary shadow"
                  @click.prevent="save"
                >
                  Save
                </button>
                <button
                  :disabled="editting.length == 0 || hasDuplicateRows"
                  style="width: fit-content"
                  class="btn mr-2 action-btn float-right btn-success shadow"
                  @click.prevent="proceed"
                >
                  Check DB Duplicates
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Duplicate check prompt -->
    <el-dialog
      :visible.sync="isConfirmationDialogVisible"
      width="28%"
      top="30vh"
      height="60vh"
      center
    >
      <div>
        <div class="row dialog-content">
          <div class="col pb-4">
            <b-icon
              v-if="state === 'DUPLICATES_ERROR'"
              icon="x-circle"
              font-scale="5"
              scale="1"
              variant="danger"
            ></b-icon>

            <b-iconstack v-else font-scale="5">
              <b-icon stacked icon="circle-fill" variant="success"></b-icon>
              <b-icon stacked icon="check" scale="1" variant="white"></b-icon>
            </b-iconstack>
          </div>
        </div>

        <div class="row dialog-content">
          <div class="col pb-4">
            <span v-if="state === 'DUPLICATES_ERROR'">Duplicates check failed</span>
            <span v-else> No duplicates found. Your data is good to go</span>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- shows a prompt , properties are defined in Mixin Object -->
    <ProgressPrompt
      :visible="isProgresPromptVisible"
      :cancel="promptCancel"
      :confirm="promptConfirm"
      :message="promptMessage"
      :title="promptTitle"
      :cancel-text="promptCancelText"
      :cancel-type="promptCancelClass"
      :confirm-text="promptConfirmText"
      :confirm-type="promptConfirmClass"
      @close="onPromptClose"
    />
  </div>
</template>

<script>
import NavigationSubNav from "../../components/NavigationSubNav.vue";
import Loader from "../../components/Loader.vue";
import TableComponent from "../../components/Table.vue";
import DataMixin from "./mixin";
import UploadResource from "../../api/fileResource";
import { mapGetters } from "vuex";
import { random } from "../../utils";

const uploadResource = new UploadResource();

export default {
  name: "DuplicatesChecker",
  components: {
    NavigationSubNav,
    Loader,
    TableComponent,
  },
  mixins: [DataMixin],
  data() {
    return {
      /**
       *
       */
      duplicateRows: [],
      /**
       *
       */
      state: "NO_RECORD",
      /**
       *
       */
      isConfirmationDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      duplicateCheckResponse: "data/duplicateCheckResponse",
      isNormalized: "data/normalizationState",
    }),
    hasDuplicateRows() {
      return this.duplicateCheckResponse && this.duplicateCheckResponse.length > 0;
    },
    loadingTitle() {
      return "Checking file for duplicates";
    },
    loadingPrompt() {
      if (this.state === "NO_RECORD") return "Please select a file to  to proceed";
      if (!this.isNormalized) return "Please perform meta-data checks before proceeding";
      return "Please wait while the uploaded data is checked for duplicate entries";
    },
    title() {
      return "Check for Duplicates in Data";
    },
    message() {
      if (!this.hasDuplicateRows) return "Your data is good to go";
      return "See conflicting values that exist in your data";
    },
    preview() {
      const data = JSON.parse(JSON.stringify(this.filteredData));
      if (!this.hasDuplicateRows) return data;
      return data.map((cell) => {
        if (this.duplicateCheckResponse.includes(cell.$id)) {
          cell["_rowVariant"] = "danger";
          cell["_isValid"] = false;
        }
        return cell;
      });
    },
  },
  mounted() {
    const query = this.$route.query;
    if ("manual" in query) {
      // show notification
      return;
    }
    return this.performDuplicatesCheck()
      .then(() => {
        //this.onCheckPassed();
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    /**
     *
     */
    performDuplicatesCheck() {
      return new Promise((resolve, reject) => {
        this.state = "CHECKING_RECORD_FOR_DUPLICATES";

        this.checkDuplicates()
          .then(async (response) => {
            this.state = response.errorMsg || "CHECK_PASSED";
            if (this.state === "NETWORK_ERROR") {
              return reject(false);
            } else if (this.state === "NORMALIZATION_REQUIRED") {
              this.$store.dispatch("data/cacheNormalizationResponse", null);
              this.$notify.error("File needs to be checked for meta-value availability");
              this.$router
                .push({ path: "/data/check-meta-data", query: { manual: 1 } })
                .then(() => {})
                .catch((e) => console.log(e));
              return reject(false);
            } else if (this.state === "DUPLICATES_ERROR") {
              await this.$store.dispatch("data/cacheD_CHECK_Response", [...response.failures]);
              await this.parseDuplicates(response.failures);
              this.isConfirmationDialogVisible = true;
              return reject(false);
            } else {
              await this.parseDuplicates([]);
              await this.$store.dispatch("data/cacheD_CHECK_Response", []);
              this.isConfirmationDialogVisible = true;
              return resolve(true);
            }
          })
          .catch((e) => {
            return reject(e);
          });
      });
    },
    /**
     *
     */
    async checkDuplicates() {
      try {
        await this.setRequestProgress(random(40, 60));
        this.isApplyingChecks = true;
        this.$progress.start();

        const response = await uploadResource.performDuplicatesCheck(this.currentRecord.id);
        await this.setRequestProgress(100);

        return { response: response.message, failures: null };
      } catch (error) {
        await this.setRequestProgress(random(80, 95));
        const statusCode = error?.response?.status || 500;
        if (500 === statusCode) {
          return { errorMsg: "NETWORK_ERROR", failures: [] };
        }
        // TODO  update failures with unique failure status
        if (400 === statusCode) {
          const reason = error?.response?.data?.detail || null;
          if (reason === "File needs to be normalized first before duplicate check") {
            return { failures: [], errorMsg: "NORMALIZATION_REQUIRED" };
          }
          const duplicatePostions = error?.response?.data?.positions || [];
          return { failures: duplicatePostions, errorMsg: "DUPLICATES_ERROR" };
        }
        return { failures: null, errorMsg: "UN_KNOWN_ERROR", error };
      } finally {
        this.$progress.done();
        this.isApplyingChecks = false;
      }
    },

    /**
     *
     */
    async parseDuplicates(positions) {
      this.duplicateRows = positions;
    },

    /**
     *
     */
    onCheckPassed() {
      setTimeout(async () => {
        this.$store.commit("data/SET_DCHECK_STATE", true);
        this.$router
          .push("/data/duplicates-db")
          .then(() => {})
          .catch((e) => console.log(e));
      }, 500);
    },
    /**
     * A callback method to handle deletions of records
     * @param {Number} count Number of deleted rows
     */
    async onRowsDeleted(count) {
      console.log(count);
      await this.$store.dispatch("data/cacheD_CHECK_Response", null);
      return this.performDuplicatesCheck()
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    },
    /**
     *Navigates to  next index on the  Next index Navigation Bar
     @see {NavigationSubNav}
     */
    async proceed() {
      if (this.hasDuplicateRows) {
        this.tableLoading = true;
        this.performDuplicatesCheck()
          .then(() => {
            this.onCheckPassed();
          })
          .catch((e) => console.log(e))
          .finally(() => {
            this.tableLoading = false;
          });
        return;
      }
      return this.onCheckPassed();
    },
    /**
     *
     */
    save() {
      this.showProgressPrompt(
        "Save progress",
        "Are you sure you want to save and continue later?",
        {
          cancel: "Continue",
          confirm: "Save and continue later",
          confirmClass: "primary",
          async confirmCallback() {
            this.$store.dispatch("data/storeData");
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  width: 85px;
}
.dialog-content {
  text-align: center;
}
</style>
